import React from 'react';

export const Hero = ({
  id = 'hero',
  background = undefined,
  maxWidth = '100%',
  title,
  image = '',
  description = '',
  button = undefined,
}) => {
  return (
    <section id={id} className="mb-8" style={{ background }}>
      <div
        className="flex flex-col-reverse items-center px-8 mx-auto overflow-hidden sm:px-10 md:px-20 md:flex-row"
        style={{ maxWidth }}
      >
        {image && (
          <div
            className={
              'md:w-5/6 w-[150vw] xs:w-[125vw] flex-1 flex-shrink-1 md:-ml-20 md:-mr-20 min-w-[20vw] sm:pt-8 mt-8'
            }
          >
            <img
              src={image}
              className="object-cover object-center rounded"
              placeholder="none"
              alt="hero"
              // backgroundColor="transparent"
            />
          </div>
        )}
        <div className="flex flex-col items-center flex-1 flex-shrink-0 mt-16 mb-16 text-center z-[1] md:-mr-12 md:mt-0 lg:flex-grow lg:pr-12 md:pr-8 md:items-start md:text-left md:mb-0">
          <h1
            className="mb-4 text-4xl font-bold  leading-snug whitespace-pre-wrap md:leading-snug lg:leading-snug "
            style={{ wordBreak: 'keep-all' }}
            children={title}
          />
          <p
            className="mb-8 text-base leading-relaxed whitespace-normal sm:whitespace-pre-wrap lg:leading-relaxed lg:text-lg"
            style={{ wordBreak: 'keep-all' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {button && (
            <div className="flex justify-center">
              <button
                className="inline-flex px-6 py-2 text-lg text-white bg-blue-500 border-0 rounded focus:outline-none hover:bg-blue-600"
                onClick={() => {
                  document
                    .querySelector('#contact')
                    ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
                }}
              >
                {button}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { Hero } from '../components/Hero';
import { ImageMasonry } from '../components/ImageMasonry';

export default function AboutPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const images = data.allFile?.edges?.map(
    ({
      node: {
        name,
        childImageSharp: { id, gatsbyImageData },
      },
    }) => ({ id, name, gatsbyImageData })
  );
  return (
    <Layout>
      <SEO title="About" />
      <div className="mt-16">
        <Hero
          title={
            <div className="">
              <span className="marker-primary">WLPO</span>는 <span className="marker-primary">월포TV</span>에서
              만들어가는 브랜드이자 <span className="marker-primary">포르쉐</span>라는 꿈을 매개로한 여러분들과 함께하는{' '}
              <span className="marker-primary">커뮤니티</span>입니다.
            </div>
          }
          description="WLPO is our new commercial brand for Porsche enthusiasts branching out of WLPO TV. Our community shares interest with those who dream of Porsche."
        />
        <ImageMasonry images={images} className="w-full h-auto px-8 mx-auto overflow-hidden sm:px-16" />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allFile(sort: { order: ASC, fields: [name] }, filter: { relativePath: { regex: "/^about_us//" } }, limit: 100) {
      edges {
        node {
          name
          childImageSharp {
            id
            gatsbyImageData
          }
        }
      }
    }
  }
`;

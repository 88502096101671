import React from 'react';
import Masonry from 'react-masonry-css';
import Zoom from 'react-medium-image-zoom';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import 'react-medium-image-zoom/dist/styles.css';

export const ImageMasonry = ({ images, className = '' }) => {
  const { width } = useWindowSize();
  return (
    <Masonry
      breakpointCols={width >= 960 ? 3 : width >= 640 ? 2 : 1}
      className={classNames('flex w-auto', className)}
      columnClassName="bg-clip-padding px-2 sm:px-4 "
    >
      {images.map(
        ({
          id,
          gatsbyImageData: {
            width,
            height,
            images: {
              sources: [{ srcSet }],
            },
          },
        }) => (
          <Zoom zoomZindex={10} overlayBgColorStart="#33333300" overlayBgColorEnd="#33333388" zoomMargin={16}>
            <img id={id} srcSet={srcSet} width={width} height={height} className="my-2 rounded sm:my-4" />
          </Zoom>
        )
      )}
    </Masonry>
  );
};
